@font-face {
    font-family: RobotoCondensed;
    src: url(/src/assets/roboto-condensed.light.ttf);
    font-weight: 800;
}
  
@font-face {
  font-family: EconomicaBold;
  src: url(/src/assets/economica-bold.ttf);
}


.gfg {

  margin-top: 20px;
  display: flex; 
}

.background-pic {
    border-radius: 10px;
    width: 4000px;
}  

.headerText{
  margin-top: 9rem;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.subHeader{ 
  font-size: 1.3rem;
  font-style: italic;
  text-align: center;
}

h2.first-txt {
  font-size: 4.2rem;
  font-family: 'EconomicaBold';
  text-align: center;
  line-height: .4;
  text-shadow: 1px 1px darkgrey;
  
}


@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {

  .background-pic {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%; 
    }
    .headerText{
      margin: auto;
      margin-top:1.5rem;
    }
    .first-txt{
      font-size: 1.4rem !important;
    }

    .subHeader{
      margin: auto;
      margin-top: -8%;
      font-size: .6rem;
    }
  
  }
   
/* mobile phone landscape mode */
@media (min-width: 667px) and (max-width: 896px) and (orientation: landscape){
  .headerText{
    margin: auto;
    margin-top: 4.5rem;
  } 
  .subHeader{
    margin: auto;
    margin-top: -3%;
    font-size: .9rem;
    font-style: italic;
  }
  h2.first-txt {
    font-size: 2.6rem;
  }

  .background-pic {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%; 
    } 
}

/* tablet portrait mode */
@media (min-width: 768px) and (max-width: 820px) and (orientation: portrait) {
  .headerText{
    margin-top: 4rem;
  } 
  h2.first-txt {
    font-size: 2.8rem;
  }
  .subHeader{
    margin: auto;
    margin-top: -2%;
    font-size: 1.1rem;
    font-style: italic;
  }

}

@media(min-width:1024px) and (max-width: 1199px) and (orientation: landscape){
  .headerText {
    margin-top: 5rem;
  }
  h2.first-txt {
    font-size: 3.2rem;
  }
}