@font-face {
    font-family: RobotoCondensed;
    src: url(/src/assets/roboto-condensed.light.ttf);
    font-weight: 900;
}

h2.service-header {
    margin-left: -6rem ;
}
.service-title {
    margin-top: -1rem;
}


.apostille-text {
    padding-top: 20px;
}

.para-text {
    padding-top: 1%;
    font-size: 1.8rem;
    font-family: 'RobotoCondensed';
    margin-left: -13rem ;
    padding-bottom: 10px;
}

.list-notes{
    font-size: 1.6rem;
    font-family: 'RobotoCondensed';
    font-weight: bolder;
    list-style-type: disc !important;
    margin-left: -2.8rem;
}

.list-services {
    font-size: 1.6rem;
    font-family: 'RobotoCondensed';
    font-weight: bolder;
    list-style-type: disc !important;
    margin-left: 2.8rem;
}

.apostille-text {
    font-size: 1.8rem;
    font-family: 'RobotoCondensed';
    font-weight: bolder; 
    margin-left: 30px;
}


.icons-title {
    font-style: italic;
    font-weight: bold;
    display: inline-block;
    cursor: pointer;   
}

.icons-obj {
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    position: relative;
    mix-blend-mode:multiply;
    margin-bottom: 20px;
    margin-left: -10rem;
}

.icons {
    width: 200px;
    background-color: #E5E4E2;
    border-radius: 100%; 
    position: relative;
    display: inline-block;
    cursor: pointer;  
}

.icons:hover{
    width: 300px;
    background-color: transparent;
}

.list-nts {
    margin-left: 2.8rem;
}

.mobile-container {
    display: none;
}
.cust-btn {
    padding-bottom: 2rem;
}
.map {
    padding-top: 5%;
    border-radius: 10px;
    margin: auto;
    display: block;
    width: 40vw;
    margin-bottom: 1vw;
}



@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait)  {
  
    h2.service-header {
        margin-left: 1rem;
    }
  
    .para-text{
        margin-top: -1.5rem;
        font-size: 1.2rem;
        margin-left: 2rem;
        word-wrap: break-word;
    }
    .icons {
        width: 5.7rem;
        position: relative;
        display: inline-block;
        cursor: pointer;  
    }
    .icons-obj {
       display: none;
     }
    .icons:hover{
        width: 8.2rem;
    }
    .service-title{
        font-size: 1.4rem;
    }
    .list-notes {
        font-size: 1rem !important;
        font-family: 'RobotoCondensed';
        word-wrap: break-word;
        margin-left: .5rem;
        padding-left: -1rem; 
        padding-right: 2rem;      
    }

    .list-services {
        /* padding-left: 1rem ; */
        margin-left: -.5rem;
         font-size: 1rem !important;
         font-family: 'RobotoCondensed';
         word-wrap: break-word !important;
     }

    .apostille-text{
        margin-top: -1.2rem;
        line-height: 2.1rem;
        font-size: 1.1rem !important;
        font-family: 'RobotoCondensed';
        word-wrap: break-word !important;
    }
    
   
    .mobile-container {
        display: initial;
    }
    .title-mobile {

        font-weight: 700;
        padding-top: 8%;
        padding-bottom: 8%;
        margin-top: -1.0rem;
        margin-left: 1.6rem;
    }
    #mobile-signingAgent, #mobile-remoteOnline {
        padding-top: 8%;
    }
}

/* phone landscape */
@media (min-width: 667px) and (max-width: 896px) and (orientation: landscape){
 h2.service-header {
    margin-left: 2rem;
 }
 .para-text {
    margin-left: 2rem;
    font-size: 1.3rem; 
 }
 .title-mobile{
    font-weight: bolder;
    margin-left: 2rem;
 }
 .list-notes {
    margin-left: -12rem;
 }
 li.list-notes, li.list-services, li.apostille-text {
    font-size: 1.3rem;
 }
 .mobile-container {
    display: initial;
}
.icons-obj {
    display: none;
  }
}

/* tablet portrait mode */
@media (min-width: 768px) and (max-width: 820px) and (orientation: portrait) {
    h2.service-header {
        margin-left: 1rem;
    }
    h4.service-title {
    font-size: 1.8rem; 
    margin-top: 2rem;
    }
    .icons-title{
        inline-size: min-content;
    }
    .icons {
        width: 150px;
        
    }
    .icons:hover{
        width: 250px;
    }
    .icons-obj {
        margin-top: -2rem;
        margin-left: 1rem;
       
    }
    .cust-btn {
        padding-bottom: 2rem;
    }
    .para-text {
        margin-left: 1.5rem;
    }
    .map {
        padding-bottom: 5%;
    }
}
/* tablet mode landscape */
@media (min-width: 1024px)  and (orientation: landscape){
    h2.service-header {
        margin-left: -6rem;
    }
    h4.service-title{
        font-size: 1.6rem;
    }
    .para-text {
        margin-left: -2.6rem;
    }
    .list-notes {
        margin-left: -14rem;
    }
}

