@font-face {
  font-family: RobotoCondensed;
  src: url(/src/assets/roboto-condensed.light.ttf);
  font-weight: 800;
}

.notaryHeader {
  margin-left: 7rem;
}

.noteRepublic-text {
  /* text-align: left; */
  font-size: 1.8rem;
  font-family: 'RobotoCondensed';
  margin-left: 10%;

}

.profile {
  margin-top: 5px;
  margin-left: 7rem;
  /* width: 75px ; */
  height: 150px;
  border-radius: 100px;
  display: flex;
  justify-content: space-evenly;
}

.notary-name {
  margin-left: 5rem;
  text-align: left;
}

/* mobile portrait */
@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
  .notaryHeader {
    margin-left: .5rem;
  }
  .noteRepublic-text {
    font-size: 1.2rem;
    font-family: 'RobotoCondensed';
    margin-left: .8rem;
    margin-top: .7rem;
  }

  .profile {
    height: 100px;
    margin-left: .5rem;
  }
  .notary-name {
    font-size: x-large;
    margin-left: 1rem;
  }

  #notaryPublic {
    margin-top: -1rem;
  }
}

/* mobile phone landscape */
@media (min-width: 667px) and (max-width: 896px) and (orientation: landscape) {
  .noteRepublic-text {
    font-size: 1.3rem;
    font-family: 'RobotoCondensed';
    margin-left: 4rem;
  }

  h2.notaryHeader {
    margin-top: 1rem;
    margin-left: 2rem;
  }

  .notary-name {
    font-size: x-large;
    margin-left: 4rem
  }

  .profile {
    height: 100px;
    margin-left: 4rem
  }
}

/* tablet portrait mode */
@media (min-width: 768px) and (max-width: 820px) and (orientation: portrait) {
  .notaryHeader {
    margin-left: 0;
  }

  .profile {
    height: 200px;
    margin-left: 0;
  }

  .notary-name {
    font-size: 2.4rem;
    margin-left: 3rem;
  }

  .noteRepublic-text {
    font-size: 1.8rem;
    font-family: 'RobotoCondensed';
    margin-left: 2.5rem;
  }
}

/* tablet landscape mode */
@media (min-width: 1024px) and (orientation: landscape) {
  .notaryHeader, .profile {
    margin-left: -6.5rem;
  }
  h3.notary-name, .noteRepublic-text {
    margin-left: -5rem;
  }
}