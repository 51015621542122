@font-face {
    font-family: RobotoCondensed;
    src: url(/src/assets/roboto-condensed.light.ttf);
    font-weight: 800;
}

@font-face {
    font-family: EconomicaBold;
    src: url(/src/assets/economica-bold.ttf);
}

.header-contacts {
    margin-left: -5rem;
}

input {
    background-color: white !important;
    margin-left: 2%;
}

.cust-btn {
    margin-left: -90px;
}
.materialize-textarea {
    background-color: white;
}

.cust-desc {
    width: 300px;

    /* margin-left:10px ; */

}

.desc-input {
    margin-top: 20px !important;
    background-color: white !important;
}

.btn {
    background-color: royalblue !important;
    height: 65px;
    width: 100px;
    border-radius: 20px !important;

}

.btn:hover {
    background-color: rgba(6, 196, 180, 0.841) !important;
}

button.btn {
    height: 60px;
    font-size: large;
    margin-left: 50%;
    position: 0;
}

select.browser-default {
    color:black !important;
}
/* mobile phone portrait mode */
@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait)  {
    .header-contacts {
        margin-left: 1rem;
    }
    
    select {
        border: 4px solid #f2f2f2 !important;
    }

    select.cust-opt {
        font-size: x-large;
    }

    .cust-btn {
        margin-left: 30%;
        margin-top: 0rem;
        /* padding-bottom: 0rem; */
    }

    button.btn {
        height: 40px;
        width: 90px;
        font-size: small;
        margin-left: 6%;
    }

    select.browser-default {
        font-size: 1.1rem;
        color:black;
    }
    
}
/* mobile phone landscape mode */
@media (min-width: 667px) and (max-width: 896px) and (orientation: landscape){
 .cust-btn {
    margin-top: 0rem;
   
    padding-bottom: 2rem;
 }
 .header-contacts {
    margin-top: 2rem;
    margin-left: 2rem;
 }
 row {
    margin-left: 4rem;
 }
}

/* tablet portrait mode */
@media (min-width: 768px) and (orientation: portrait) {

    .header-contacts {
        margin-left:2rem;
    }
    select {
        font-size: 1.6rem;
    }
}

 /* tablet landscape mode */
 @media(min-width:1024px) and (orientation: landscape){

    .header-contacts {
        margin-left: -6rem;
    }
 }