@font-face {
  font-family: RobotoCondensed;
  src: url(assets/roboto-condensed.light.ttf);
  font-weight: 800;
}

@font-face {
  font-family: EconomicaBold;
  src: url(assets/economica-bold.ttf);
}


.sticky {
  position: sticky;
  top: 0;
  z-index: 999;

}

.logo-title {
  position: relative;
  margin-top: 10px;
  margin-left: 20px;
  height: 80px;
}

/*  */
.logo-name {
  font-family: EconomicaBold;
  color: black !important;
  /* overflow-wrap: break-word; */
  font-size: 1.8rem;
  position: relative;
  position: fixed;
  left: 110px;
  /* right:0; */
  margin: auto;
  top: 33;


}

.logo-name::before {
  content: '|';
  padding: 0 1rem;
  height: 10px;
}

nav.nav {
  height: 100px;
  font-size: 24px;

}

.nav-items {
  margin-top: 20px;
  font-size: 26px;

}

.nav-sec {

  margin-top: 20px;
}

.right {
  display: flex;
  margin-right: 20px;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
}

.nav {
  background-color: royalblue;
}


body {
  background-color: #E5E4E2;
}

#sec-1,
#sec-2,
#sec-3,
#sec-4 {
  cursor: pointer;
  scroll-behavior: auto;
  font-size: large;
  margin-top: 10%;
}

#sec-1::after,
#sec-2::after,
#sec-3::after {
  content: '|';
  padding: 0 1rem;
}

/* .icon-btn {
  font-size: small;
  background-color: royalblue;
  border-radius: 100px;
}
.icon-btn:hover {
  font-size: small;
  background-color: lightgray;

} */
.title-fade-out {
  opacity: 0;
  animation-name: fade-out;
  animation-duration: 2s;
}

.title-fade-in {
  opacity: 100;
  animation-name: fade-in;
  animation-duration: 2s;
}

.sidenav {

  list-style: none;
}


#sec-1,
#sec-2,
#sec-3,
#sec-4 {
  scroll-behavior: auto;
}


body {
  background-color: #E5E4E2;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'EconomicaBold';


}


nav {
  background-color: royalblue !important;
  height: 100px !important;
}

.page-footer {
  background-color: royalblue !important;
}

/* mobile phone portrait mode */
@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
  .logo-name {
    display: none;
  }

  .brand-logo {
    height: 4.5rem;
    margin-top: 1rem;
  }

  #mobile-nav {
    margin-top: 7.2rem;
    width: 200px;
    height: 200px;
    background-color: lightgrey;
    position: fixed;
  }

  .right {

    display: block;
  }

  li.sidenav:hover {
    background-color: darkgrey;
  }

  #sidenav-sec-1,
  #sidenav-sec-2,
  #sidenav-sec-3,
  #sidenav-sec-4 {
    font-size: 1rem;
    font-weight: 600;
  }

  i.material-icons {
    margin-top: 20px;
  }

  .mobile-icon {
    margin-top: 2rem;
    font-weight: bolder;
    color: black;
    font-size: 2.8rem;
    text-align: center;
  }
  
}

/* mobile phone landscape mode */
@media (min-width: 667px) and (max-width: 896px) and (orientation: landscape) {
  .mobile-icon {
    margin-top: 2rem;
    font-weight: bolder;
    color: black;
    font-size: 2.8rem;
    text-align: center;
  }

  #mobile-nav {
    margin-top: 7.2rem;
    width: 200px;
    height: 200px;
    background-color: lightgrey;
    position: fixed;
    display: block;
  }

  #sidenav-sec-1,
  #sidenav-sec-2,
  #sidenav-sec-3,
  #sidenav-sec-4 {
    font-size: 1rem;
    font-weight: 600;
  }

  i.material-icons {
    margin-top: 20px;
  }
}

/* tablet portrait mode */
@media (min-width: 768px) and (max-width: 820px) and (orientation: portrait) {
  .mobile-icon {

    font-weight: bolder;
    color: black;
    font-size: 2.8rem;
    text-align: center;

  }

  i.material-icons {
    margin-top: 15px;
  }

  li.sidenav:hover {
    background-color: darkgrey;
  }

  #sidenav-sec-1,
  #sidenav-sec-2,
  #sidenav-sec-3,
  #sidenav-sec-4 {
    font-size: 1rem;
    font-weight: 600;
  }
  .right {
    display: block;
  }
  #mobile-nav {
    margin-top: 7.2rem;
    width: 200px;
    height: 200px;
    background-color: lightgrey;
    position: fixed;
    display: block;
  }
}

