@font-face {
    font-family: RobotoCondensed;
    src: url(/src/assets/roboto-condensed.light.ttf);
    font-weight: 800;
  }


.about-title{
  margin-top: 0rem;
  margin-left: 6rem;
}
.about-text {
    text-align: left;
    font-size: 1.8rem;
    font-family: 'RobotoCondensed';
    margin-top: 0;
    margin-left: 10%;     
}



@media  (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
  .about-title{
    margin-top: -1.5rem;
    margin-left: -2rem;
  }
  .about-text {
    margin-top: -3rem !important;
    margin-left: 1.5rem;
    font-size: 1.2rem;
    font-family: 'RobotoCondensed';  
  }
  h2 {
    font-size: 2.40rem !important;
  }
 #About{
   margin-left: 0rem;
   margin-top: -4rem;
  }
  .about-text {
    margin-top: -2rem;
  }
}
/* mobile phone landscape */
@media (min-width: 667px) and (max-width: 896px) and (orientation: landscape){
  .about-title{
    margin-top: 1rem;
    margin-left: 0;
  }
  .about-text {
    font-size: 1.3rem;
  }
  h2.about-title {
    margin-top: -2rem;
  }
  p.about-text {
    margin-top: -2rem;
  }
}

/* tablet portrait mode */
@media (min-width: 768px) and (max-width: 820px) and (orientation: portrait) {
  .about-title {
    margin-top: -1.5rem;
    margin-left: -2rem;
  }
  .about-text {
    margin-left: 2rem;
    margin-top: -3rem;
    font-size: 1.8rem;
    text-align: justify;
  }
}
 /* tablet landscape mode */
@media(min-width:1024px) and (orientation: landscape){
  h2.about-title{
    margin-left:-8rem;
    
  }
  .about-text {
    margin-top: -2.5rem;
    margin-left: -4.5rem;
    word-wrap: break-word;
    text-align: justify;
  }
}
